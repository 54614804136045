import {
  getSession,
  SessionProvider,
  signIn,
  useSession,
} from "next-auth/react";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import { appWithTranslation } from "next-i18next";
import getConfig from "next/config";
import { AppProps } from "next/app";
import { SWRConfig } from "swr";
import axios from "axios";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import Script from "next/script";

import nextI18nextConfig from "../../next-i18next.config";
import theme from "../theme";
import Loader from "@/components/common/Loader";
import * as fbq from "@/utils/fpixel";
import ReactGA from "react-ga4";

const { publicRuntimeConfig } = getConfig();
if (publicRuntimeConfig.analyticsId)
  ReactGA.initialize(publicRuntimeConfig.analyticsId);

axios.defaults.baseURL = `${publicRuntimeConfig.basePath}/app-api`;
axios.interceptors.request.use(async (config) => {
  const session = await getSession();
  //@ts-ignore
  if (!session?.token || typeof session.token !== "string") return config;

  config.headers = {
    ...config.headers,
    //@ts-ignore
    Authorization: session?.token,
  };

  return config;
});

export const fetcher = (url) => axios.get(url).then((res) => res.data);

interface CustomAppProps extends Omit<AppProps, "Component"> {
  Component: AppProps["Component"] & {
    Layout?: JSX.Element;
    auth?: boolean;
  } & any;
}

function App({
  Component,
  pageProps: { session, ...pageProps },
}: CustomAppProps) {
  const AuthComponent = Component.auth ? (
    <Auth>
      <Component {...pageProps} />
    </Auth>
  ) : (
    <Component {...pageProps} />
  );

  const router = useRouter();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    fbq.pageview();

    const handleRouteChange = () => {
      fbq.pageview();
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />
      <SessionProvider
        session={session}
        basePath={`${publicRuntimeConfig.basePath}/api/auth`}
      >
        <ChakraProvider resetCSS theme={theme}>
          <SWRConfig
            value={{
              fetcher,
            }}
          >
            {AuthComponent}
          </SWRConfig>
        </ChakraProvider>
      </SessionProvider>
    </>
  );
}

function Auth({ children }) {
  const { data: session, status } = useSession();
  const isUser = !!session?.user?.id;

  React.useEffect(() => {
    if (status === "loading") return; // Do nothing while loading
    if (status === "unauthenticated" || !isUser) signIn(); // If not authenticated, force log in
  }, [status, isUser]);

  if (isUser) {
    return children;
  }

  // Session is being fetched, or no user.
  return (
    <ChakraProvider>
      <Flex justifyContent={"center"} p={20}>
        <Loader size={"xl"} />
      </Flex>
    </ChakraProvider>
  );
}

export default appWithTranslation(App, nextI18nextConfig);
